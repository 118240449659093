import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import Divider from '@material-ui/core/Divider';
import environment from '../../Environment';
import DrawerItem from './DrawerItem';


export default function IBSimMenuItem(props) {
  const { menuItem } = props;
  return (
    <QueryRenderer
      environment={environment}
      query={
        graphql`
            query IBSimMenuItemIsPaperQuery{
              adminMeta {
                isPaper
                pendingApplications
                pendingTransfers
              }
            }
          `
      }
      render={({ childProps, error }) => {
        if (error) {
          return <React.Fragment />;
        }
        if (childProps) {
          const { adminMeta } = childProps;
          if (adminMeta.isPaper) {
            const iconClass = menuItem[1];
            const caption = menuItem[2];
            const link = menuItem[3];
            return (
              <React.Fragment>
                <Divider />
                <DrawerItem
                  iconClass={iconClass}
                  caption={caption}
                  link={link}
                />
              </React.Fragment>
            );
          }
        }
        return <React.Fragment />;
      }}
    />
  );
}
